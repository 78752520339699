import { Notification } from 'components/DemandManagement/style';
import { Divider, InlineLink, TextFlow, ParagraphBaseHeavy } from 'components/Typography';
import { STRINGS } from 'config';
import { useAppDispatch, useAppState } from 'store/configureStore';
import React, { useEffect, useMemo } from 'react';
import { apiGetAdminAvailability, getDemandManagementEnabled } from 'store/action/demandManagement';
import { TableContainer, TableHeaderDivider, TableHeaderSmallText, TableHeaderTitleText } from 'components/Table';
import { HeaderColumn, HeaderRow } from 'components/Table/tableHeader';
import { ScheduledClosures } from 'components/ScheduledClosures';
import { AdminAvailability } from 'components/AdminAvailability';
import { MoreInfo } from './moreInfo';

const {
  PRIMARY_CARE_DOMAIN,
  REQUEST_STATUS: { PENDING, IDLE },
} = STRINGS;

const DMDisabledNoticeText = () => (
  <>
    Your practice doesn&apos;t have demand management enabled. If you need to change this, please contact us through Live Chat or email at&nbsp;
    <InlineLink href="mailto:practice.support@econsult.health">practice.support@econsult.health</InlineLink>.
  </>
);

export const DemandManagement = () => {
  const dispatch = useAppDispatch();
  const {
    app: {
      selectedPractice,
    },
    demandManagement: {
      demandManagementEnabled,
      demandManagementEnabledStatus,
    },
  } = useAppState();

  const demandManagementSettingUrl = useMemo(() => (
    `https://${selectedPractice.id}.${PRIMARY_CARE_DOMAIN}/manage/${selectedPractice.pcmCode}`
  ), [selectedPractice.id, selectedPractice.pcmCode]);

  const showDMDisabledNotice = !demandManagementEnabled && ![PENDING, IDLE].includes(demandManagementEnabledStatus);

  useEffect(() => {
    apiGetAdminAvailability(selectedPractice.id)(dispatch);
    getDemandManagementEnabled(selectedPractice.id)(dispatch);
  }, [dispatch, selectedPractice.id]);

  return (
    <TableContainer id="SkipToUserListMainContent" data-automation="DemandManagementWrapper">
      <HeaderColumn>
        <HeaderRow>
          <HeaderColumn>
            <TableHeaderTitleText as="h1" data-automation="DemandManagementPageTitleText">
              Demand management
            </TableHeaderTitleText>
            <TableHeaderSmallText data-automation="DemandManagementPageSubTitleText">
              Manage your eConsult demand to suit your practice&apos;s needs.
            </TableHeaderSmallText>
          </HeaderColumn>
        </HeaderRow>
        <TableHeaderDivider />
      </HeaderColumn>
      <TextFlow>
        {showDMDisabledNotice && <Notification automationName="demandManagementDisabledNotice" textContent={<DMDisabledNoticeText />} />}
        <ScheduledClosures />
        <Divider />
        <ParagraphBaseHeavy as="h2">Other settings</ParagraphBaseHeavy>
        <AdminAvailability />
        <Divider />
        <MoreInfo demandManagementEnabled={demandManagementEnabled} demandManagementSettingUrl={demandManagementSettingUrl} />
      </TextFlow>
    </TableContainer>
  );
};
