import { ArrowRightIcon } from '@econsult/econsult-ui/dist/assets';
import { CardDescription, CardTitle, CardWrapper, HomeCardButton, HomeLinksWrapper, HomeWelcomeWrapper, HomeWrapper, StyledWelcomeGraphic } from 'components/Home/style';
import { TableHeaderDivider, TableHeaderSmallText, TableHeaderTitleText } from 'components/Table';
import { HeaderColumn, HeaderRow } from 'components/Table/tableHeader';
import { ParagraphBaseHeavy, ParagraphXSmallRegular, TextFlow } from 'components/Typography';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toTitleCase } from 'utils/string';

export const homeLinks = {
  userManagement: {
    id: 'user-management',
    title: 'User Management',
    description: 'Manage the list of users in your practice using the Smart Inbox.',
  },
  demandManagement: {
    id: 'demand-management',
    title: 'Demand Management',
    description: 'Manage your eConsult demand to suit your practice\'s needs.',
  },
  otherSettings: {
    id: 'other-settings',
    title: 'Other settings',
    description: 'Manage other settings to improve your eConsult experience.',
  },
};

const HomeLinkCard = ({ linkProps }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const idCapitalised = toTitleCase(linkProps.id).replace('-', '').replace(' ', '');
  return (
    <CardWrapper data-automation={`LinkCard${idCapitalised}`}>
      <CardTitle data-automation="LinkCardTitle">{linkProps.title}</CardTitle>

      <CardDescription data-automation="LinkCardDescription">{linkProps.description}</CardDescription>

      <HomeCardButton
        icon={<ArrowRightIcon />}
        onClick={() => navigate(linkProps.id + location.search)}
        label="Manage"
        iconPosition="left"
        size="small"
        fillStyle="borderless"
        data-automation={`LinkCardButton${idCapitalised}`}
      >
        <span>
          Manage
        </span>
      </HomeCardButton>
    </CardWrapper>
  );
};

export const Home = () => (
  <HomeWrapper id="SkipToUserListMainContent" data-automation="HomeWrapper">
    <HeaderColumn>
      <HeaderRow>
        <HeaderColumn>
          <TableHeaderTitleText as="h1" data-automation="HomePageTitleText">
            Settings home
          </TableHeaderTitleText>
          <TableHeaderSmallText data-automation="HomeDescription">
            Manage and configure your practice’s eConsult experience.
          </TableHeaderSmallText>
        </HeaderColumn>
      </HeaderRow>
      <TableHeaderDivider />
    </HeaderColumn>

    <HomeLinksWrapper>
      <HomeLinkCard linkProps={homeLinks.userManagement} />

      <HomeLinkCard linkProps={homeLinks.demandManagement} />

      <HomeLinkCard linkProps={homeLinks.otherSettings} />

    </HomeLinksWrapper>

    <HomeWelcomeWrapper>
      <StyledWelcomeGraphic data-automation="HomePageWelcomeGraphic" />

      <TextFlow data-automation="HomePageWelcomeText">
        <ParagraphBaseHeavy as="h2">Welcome to your eConsult settings portal!</ParagraphBaseHeavy>
        <ParagraphXSmallRegular>
          As we continue to grow and improve our platform we want to make it easier for you to customise and manage your eConsult experience.
        </ParagraphXSmallRegular>

        <ParagraphXSmallRegular>
          If there is anything you would like to see added please reach out and let us know!
        </ParagraphXSmallRegular>
      </TextFlow>
    </HomeWelcomeWrapper>
  </HomeWrapper>
);
