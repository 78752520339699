import React from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useAppState } from 'store/configureStore';

export const PracticeOnlyRoutes = ({ redirectPath }) => {
  const { app: { selectedPracticeIsEhub } } = useAppState();
  const navigate = useNavigate();
  const location = useLocation();
  return selectedPracticeIsEhub ? navigate(redirectPath + location.search, { replace: true }) : <Outlet />;
};
