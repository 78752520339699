import React from 'react';
import { Link } from 'react-router-dom';
import { SettingsIcon, HeadsetMicIcon, InboxIcon, BarChartFilledIcon } from '@econsult/econsult-ui/dist/assets';
import { AppBar as ECAppBar } from '@econsult/econsult-ui/dist/components/AppBar/styles';
import { AppBarButton } from '@econsult/econsult-ui/dist/components/AppBar/AppBarButton';
import { Feature, RenderOn } from '@econsult/econsult-ui/dist/components/FlagControlledFeature';
import { STRINGS } from 'config';
import { useAppState } from 'store/configureStore';

const { PRIMARY_CARE_DOMAIN, API: { SMART_INBOX_URL } } = STRINGS;
const { SMART_INBOX_NEW_ELITE, SMART_INBOX_REPORTING_ENABLED } = STRINGS.FEATURE_FLAGS;

export const AppBar = () => {
  const state = useAppState();
  const { app: { selectedPractice, selectedPracticeIsEhub }, featureFlags } = state;

  const eLiteTemplateId = featureFlags[SMART_INBOX_NEW_ELITE] ? 'GEC_SEL' : 'GEC_ECL';
  const eLiteUrl = `https://${selectedPractice?.id}.${PRIMARY_CARE_DOMAIN}/react-consult-econsult-lite?codeName=${eLiteTemplateId}`;

  return !selectedPracticeIsEhub && (
    <ECAppBar>
      <AppBarButton
        label="Inbox"
        icon={<InboxIcon />}
        automationSuffix="Inbox"
        href={SMART_INBOX_URL}
      />
      <AppBarButton
        label="eLite"
        icon={<HeadsetMicIcon />}
        href={eLiteUrl}
        automationSuffix="EconsultLite"
      />
      <Feature flags={[SMART_INBOX_REPORTING_ENABLED]}>
        <RenderOn>
          <AppBarButton
            label="Reporting"
            icon={<BarChartFilledIcon />}
            automationSuffix="Reporting"
            href={`${SMART_INBOX_URL}reports`}
          />
        </RenderOn>
      </Feature>
      <AppBarButton
        label="Settings"
        icon={<SettingsIcon />}
        automationSuffix="Settings"
        as={Link}
        to="/"
        target="_self"
      />
    </ECAppBar>
  );
};
