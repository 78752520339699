import _merge from 'lodash/merge';
import { STRINGS as CONSTANTS } from './constants';
import { developmentConfig } from './develop';
import { testConfig } from './test';
import { dteConfig } from './dte';
import { productionConfig } from './production';
import { stagingConfig } from './staging';
import { unittestConfig } from './unittest';

const environment = process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : 'development';

const getEnvironment = () => {
  switch (environment) {
    case 'production':
      return productionConfig;
    case 'test':
      return testConfig;
    case 'staging':
      return stagingConfig;
    case 'dte':
      return dteConfig;
    case 'development':
      return developmentConfig;
    case 'unittest':
      return unittestConfig;
    default:
      return {};
  }
};

export const STRINGS = _merge({}, CONSTANTS, getEnvironment());
